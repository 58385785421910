import { useState } from "react";
import {
  Page,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Button,
  List,
  ListInput,
  ListItem,
  SwipeoutActions,
  SwipeoutButton,
} from "framework7-react";
import LabelRequired from "components/LabelRequired";
import VoiceButton from "components/VoiceButton";
import styles from "./index.module.scss";

import type { FC } from "react";

const AccountingPage: FC = () => {
  const [listItem, setListItem] = useState([1, 2, 3, 4, 5, 6]);
  return (
    <Page className={styles.page}>
      <Navbar>
        <NavLeft>
          <Button tonal>重置</Button>
        </NavLeft>
        <NavTitle>爱记账信息科技有限公司</NavTitle>
        <NavRight>
          <Button
            fill
            className="ripple"
          >
            确定
          </Button>
        </NavRight>
      </Navbar>

      <List
        strong
        dividers
        inset
        className="my-0 mx-[5px] pt-[10px]"
      >
        <ListInput
          label="凭证编码"
          type="text"
          placeholder="请输入凭证编码"
          clearButton
        />
        <ListInput
          required
          clearButton
          type="datepicker"
          calendarParams={{
            sheetPush: true,
            maxDate: new Date(),
            closeOnSelect: true,
          }}
          placeholder="请选择业务发生日期"
        >
          <LabelRequired slot="label">业务发生日期</LabelRequired>
        </ListInput>
        <ListInput
          required
          resizable
          clearButton
          type="textarea"
          placeholder="请输入摘要"
        >
          <LabelRequired slot="label">摘要</LabelRequired>
        </ListInput>
      </List>

      <div
        className="bg-white rounded-[8px] m-[10px] overflow-hidden
      "
      >
        <div className="flex justify-between gap-[4px]  px-[16px] py-[10px] ">
          <LabelRequired>凭证明细</LabelRequired>
          <Button
            tonal
            href="/certEdit/"
            iconMaterial="add_circle_fill"
            className="text-[12px] w-auto flex-y-center gap-[4px]"
            iconSize={18}
          >
            手工创建行项目
          </Button>
        </div>
        <List
          dividers
          className="my-0 mx-0"
        >
          {listItem.map((item) => (
            <ListItem
              link
              swipeout
              key={item}
              header="库存商品"
              after="查看详情"
            >
              <span
                slot="title"
                className="text-gray-400 pt-[4px]"
              >
                500000 CNY
              </span>
              <SwipeoutActions right>
                <SwipeoutButton
                  delete
                  confirmText="确定删除此项凭证吗?"
                >
                  删除
                </SwipeoutButton>
              </SwipeoutActions>
            </ListItem>
          ))}
        </List>
      </div>

      <VoiceButton />
    </Page>
  );
};

export default AccountingPage;
