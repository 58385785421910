import { useEffect } from "react";
import { Capacitor } from "@capacitor/core";
import { App as F7APP, View, f7ready } from "framework7-react";

import { SplashScreen } from "@capacitor/splash-screen";

import routes from "./router";

import type { Framework7Parameters } from "framework7/types";

const isNative = Capacitor.isNativePlatform();

const App = () => {
  const params: Framework7Parameters = {
    name: "AI-Accounting",
    theme: "ios",
    routes,
    colors: {
      primary: "#6467F0",
    },
    touch: {
      iosTouchRipple: true,
    },
    dialog: {
      title: "提示",
      buttonOk: "确定",
      buttonCancel: "取消",
    },
  };

  useEffect(() => {
    f7ready(() => {
      if (isNative) {
        SplashScreen.hide();
      }
    });
  }, []);

  return (
    <F7APP {...params}>
      <View
        url="/"
        main={true}
        className="safe-areas"
      />
    </F7APP>
  );
};

export default App;
