import { useState } from "react";
import { Page, Navbar, Subnavbar, Searchbar, Segmented, Button, Icon } from "framework7-react";
import VoiceButton from "components/VoiceButton";
import styles from "./index.module.scss";

import type { FC } from "react";

const Mine: FC<AIApp.Page> = () => {
  const [activeStateButton, setActiveStateButton] = useState(0);
  return (
    <Page className={styles.page}>
      <Navbar title="有问必答">
        <Subnavbar inner={false}>
          <Searchbar
            backdrop={false}
            outline={true}
            disableButtonText="取消"
            className={styles["search-bar"]}
            placeholder="请输入关键词搜索"
          ></Searchbar>
        </Subnavbar>
      </Navbar>
      <div className="py-[10px] px-[8px] bg-white">
        <Segmented
          strong
          tag="p"
        >
          <Button
            active={activeStateButton === 0}
            onClick={() => setActiveStateButton(0)}
          >
            热门问题
          </Button>
          <Button
            active={activeStateButton === 1}
            onClick={() => setActiveStateButton(1)}
          >
            历史提问
          </Button>
        </Segmented>

        <div className="pt-[20px] pb-[10px] flex flex-col gap-[20px] ">
          {[1, 2, 4, 5, 6].map((item) => (
            <div
              className="flex-y-center gap-[8px] pl-[10px]"
              key={item}
            >
              <Icon
                f7="wand_stars"
                size={18}
              />
              <p className="text-[13px]">按照中国的会计准则，收入增加在借方还是贷方？</p>
            </div>
          ))}
        </div>
      </div>

      <VoiceButton text="我要提问" />
    </Page>
  );
};

export default Mine;
