import { useState } from "react";
import {
  Page,
  Navbar,
  Button,
  Icon,
  List,
  ListItem,
  ListInput,
  Actions,
  ActionsGroup,
  ActionsLabel,
  ActionsButton,
} from "framework7-react";
import Chart, { echarts } from "components/Chart";

import type { FC } from "react";

const ReportPage: FC = () => {
  const [users, setUsers] = useState([1, 2, 3, 34, 5, 6]);

  return (
    <Page>
      <Navbar title="报表统计" />
      <div className="flex flex-col gap-[4px] pt-[15px] px-[20px] my-[10px] bg-white">
        <div className="flex-y-center gap-[10px] ">
          <Button
            tonal
            type="button"
            className="w-fit"
            actionsOpen="#actions-two-groups"
          >
            销售收入分析 <Icon material="keyboard_arrow_down" />
          </Button>
          <Button
            tonal
            iconSize={16}
            iconF7="cloud_download"
          />
        </div>
        <div className="flex-y-center">
          <span className="text-[13px]">查询日期：</span>
          <List className="m-0 p-0 flex-1">
            <ListInput
              readonly
              clearButton
              type="datepicker"
              className="p-0"
              placeholder="请选择时间"
              calendarParams={{
                backdrop: true,
                rangePicker: true,
                maxDate: new Date(),
                on: {
                  change(calendar, values) {
                    console.log(values);
                    if ((values as any[]).length === 2) {
                      calendar.close();
                    }
                  },
                },
              }}
            ></ListInput>
          </List>
        </div>
      </div>
      <div className="bg-white">
        <Chart
          initOptions={{
            height: 260,
          }}
          chartOptions={{
            title: {
              text: "单位：万元",
              textStyle: {
                fontSize: 12,
                color: "#aaa",
              },
              padding: [15, 0, 0, 20],
            },
            tooltip: {
              trigger: "axis",
              position: function (pt: any) {
                return [pt[0], "10%"];
              },
            },
            grid: {
              show: false,
            },
            xAxis: {
              type: "category",
              data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            },
            yAxis: {
              type: "value",
              show: false,
            },
            series: [
              {
                data: [820, 932, 901, 934, 1290, 1330, 1320],
                type: "line",
                smooth: true,
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "rgba(100, 103, 240,0.5)",
                    },
                    {
                      offset: 1,
                      color: "rgba(100, 103, 240,0.1)",
                    },
                  ]),
                },
              },
            ],
          }}
        />

        <Chart
          initOptions={{
            height: 260,
          }}
          chartOptions={{
            tooltip: {
              trigger: "axis",
              position: function (pt: any) {
                return [pt[0], "10%"];
              },
            },
            grid: {
              show: false,
            },
            xAxis: {
              type: "category",
              data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                data: [120, 200, 150, 80, 70, 110, 130],
                type: "bar",
              },
            ],
          }}
        />

        <Chart
          initOptions={{
            height: 260,
          }}
          chartOptions={{
            tooltip: {
              trigger: "axis",
              position: function (pt: any) {
                return [pt[0], "10%"];
              },
            },
            xAxis: {
              type: "category",
              data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                data: [820, 932, 901, 934, 1290, 1330, 1320],
                type: "line",
              },
            ],
          }}
        />
      </div>

      <div className="bg-white p-[20px] my-[10px]">
        <p className="header flex-y-center">
          <span>客户排行榜</span>
        </p>
        <List
          strong
          dividers
          className="my-[10px]"
        >
          {users.map((user) => (
            <ListItem
              key={user}
              header="客户名称"
              title="2024-05-06"
              after="80000 CNY"
            >
              <Icon
                slot="media"
                f7="person"
              />
            </ListItem>
          ))}
        </List>
      </div>

      <Actions id="actions-two-groups">
        <ActionsGroup>
          <ActionsLabel>切换图表</ActionsLabel>
          <ActionsButton
            textColor="#222"
            className="text-[14px]"
          >
            销售收入分析
          </ActionsButton>
          <ActionsButton
            textColor="#222"
            className="text-[14px]"
          >
            每日资产情况
          </ActionsButton>
          <ActionsButton
            textColor="#222"
            className="text-[14px]"
          >
            资产负债率
          </ActionsButton>
        </ActionsGroup>
        <ActionsGroup>
          <ActionsButton
            color="red"
            className="text-[15px]"
          >
            取消
          </ActionsButton>
        </ActionsGroup>
      </Actions>
    </Page>
  );
};

export default ReportPage;
