import Tabs from "../pages/tabs";
import Home from "../pages/home";
import Questions from "../pages/questions";
import ReportsPage from "src/pages/reports";
import AccountingPage from "src/pages/accounting";
import CertEditPage from "src/pages/certEdit";

import type { Router } from "framework7/types";

const routes: Router.RouteParameters[] = [
  {
    path: "/",
    component: Tabs,
    tabs: [
      {
        path: "/",
        id: "home",
        component: Home,
      },
      {
        path: "/accounting/",
        id: "accounting",
        component: AccountingPage,
      },
      {
        path: "/report/",
        id: "report",
        component: ReportsPage,
      },
      {
        path: "/question/",
        id: "question",
        component: Questions,
      },
    ],
  },
  {
    path: "/certEdit/",
    component: CertEditPage,
  },
];

export default routes;
