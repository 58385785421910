import { useRef, useEffect } from "react";
import { Input, f7, Toolbar } from "framework7-react";

import type { FC } from "react";
import type { Picker } from "framework7/types";
import type { InputProps } from "framework7-react/components/input.js";

type ListItemPickerProps = InputProps & {
  onPicker?: (value: unknown) => void;
  placeholder?: string;
  options?: {
    label: string;
    value: string | number;
  }[];
  title?: string;
  pickerParams?: Picker.Parameters;
};
const InputPicker: FC<ListItemPickerProps> = (props) => {
  const {
    onPicker,
    title = "请选择",
    placeholder = "请选择",
    children,
    options = [],
    pickerParams = {},
    ...restProps
  } = props;

  const inputEl = useRef<HTMLInputElement>(null);
  const pickerRef = useRef<Picker.Picker | null>(null);
  const pickerInit = () => {
    if (!options?.length || !inputEl.current) return;
    pickerRef.current = f7.picker.create({
      inputEl: inputEl.current,
      rotateEffect: true,
      backdrop: true,
      toolbarCloseText: "确定",
      formatValue(values) {
        return values[1] as any;
      },
      cols: [
        {
          textAlign: "center",
          values: options.map((item) => item.label),
        },
      ],
      on: {
        open(picker) {
          const value = options.find((item) => item.label === (picker.value as any[])[0]);
          picker.$el.find(".picker-confirm").on("click", function () {
            picker.close();
            if (value) {
              onPicker?.(value.value);
            }
          });
        },
      },
      renderToolbar() {
        return `<div class="toolbar">
          <div class="toolbar-inner">
            <a href="#" class="link text-[14px] sheet-close">取消</a>
            <a href="#" class="link text-[14px]">${title}</a>
            <a href="#" class="link text-[14px] picker-confirm">确认</a>
          </div>
        </div>`;
      },
      ...pickerParams,
    });
  };
  useEffect(() => {
    !pickerRef.current && pickerInit();
    return () => {
      pickerRef.current?.destroy();
      pickerRef.current = null;
    };
  }, [options, pickerParams, title]);

  return (
    <Input
      {...restProps}
      className="h-full"
    >
      <input
        ref={inputEl}
        placeholder={placeholder}
        className="w-full text-right bg-transparent text-[13px]"
      />
    </Input>
  );
};

export default InputPicker;
