import { Page, Navbar, NavTitle } from "framework7-react";

import type { FC } from "react";

const Home: FC<AIApp.Page> = () => {
  return (
    <Page name="home">
      <Navbar>
        <NavTitle>AI记账</NavTitle>
      </Navbar>
      <div style={{ height: "100vh" }}>123123123123</div>
    </Page>
  );
};

export default Home;
