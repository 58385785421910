import { Fab, Icon } from "framework7-react";

import type { FC } from "react";

type VoiceButtonProps = {
  text?: string;
};
const VoiceButton: FC<VoiceButtonProps> = ({ text = "按住说话" }) => {
  return (
    <Fab
      slot="fixed"
      text={text}
      position="center-bottom"
    >
      <Icon material="mic_fill" />
    </Fab>
  );
};

export default VoiceButton;
