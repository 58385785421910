import { useRef } from "react";
import {
  Page,
  Navbar,
  NavRight,
  NavTitle,
  Button,
  List,
  Input,
  ListItem,
  f7,
} from "framework7-react";
import type { FC } from "react";
import type { Tooltip } from "framework7/types";
import LabelRequired from "components/LabelRequired";
import VoiceButton from "components/VoiceButton";
import InputPicker from "src/components/InputPicker";

const CertEditPage: FC = () => {
  const deleteTooltip = useRef<Tooltip.Tooltip | null>(null);
  const nextButtonTooltip = useRef<Tooltip.Tooltip | null>(null);

  const onPageInit = () => {};
  const onPageBeforeRemove = () => {
    if (deleteTooltip.current) deleteTooltip.current.destroy();
    if (nextButtonTooltip.current) nextButtonTooltip.current.destroy();
  };
  return (
    <Page
      onPageInit={onPageInit}
      onPageBeforeRemove={onPageBeforeRemove}
    >
      <Navbar backLink>
        <NavTitle>凭证行项目</NavTitle>
        <NavRight>
          <Button
            type="button"
            iconF7="trash"
            iconSize={16}
            className="ripple delete-button"
            onClick={() => {
              f7.dialog.confirm("确定要删除吗?", () => {});
            }}
          />
          <Button
            type="button"
            iconSize={22}
            iconMaterial="arrow_circle_right"
            className="next-button"
          ></Button>
        </NavRight>
      </Navbar>

      <List
        strong
        dividers
        className="my-[20px]"
      >
        <ListItem link>
          <LabelRequired slot="title">借贷方向</LabelRequired>
          <InputPicker
            slot="after"
            value=""
            title="借贷方向"
            onPicker={(value) => {
              console.log(value, "===");
            }}
            options={[
              { label: "选项1", value: 0 },
              { label: "选项2", value: 1 },
              { label: "选项3", value: 2 },
            ]}
          />
        </ListItem>

        <ListItem link>
          <LabelRequired slot="title">CNY</LabelRequired>
          <InputPicker
            slot="after"
            value=""
            title="CNY"
            options={[
              { label: "货币", value: 0 },
              { label: "美元", value: 1 },
              { label: "欧元", value: 2 },
            ]}
          />
        </ListItem>
        <ListItem link>
          <LabelRequired slot="title">会计科目</LabelRequired>
          <InputPicker
            slot="after"
            value=""
            title="会计科目"
            options={[
              { label: "会计科目1", value: 0 },
              { label: "会计科目2", value: 1 },
              { label: "会计科目3", value: 2 },
            ]}
          />
        </ListItem>
        <ListItem
          link
          title="客户/供应商"
        >
          <InputPicker
            slot="after"
            value=""
            title="客户/供应商"
            options={[
              { label: "客户1", value: 0 },
              { label: "客户2", value: 1 },
              { label: "客户3", value: 2 },
            ]}
          />
        </ListItem>
        <ListItem title="记账金额">
          <Input
            wrap
            slot="after"
            clearButton
          >
            <input
              placeholder="请输入记账金额"
              className="text-right"
            />
          </Input>
        </ListItem>
        <ListItem
          after="自动计算"
          title="本位币金额"
        ></ListItem>
        <ListItem title="行备注">
          <Input
            wrap
            slot="after"
            clearButton
          >
            <input
              placeholder="请输入行备注"
              className="text-right"
            />
          </Input>
        </ListItem>
      </List>
      <div className="flex justify-end px-[20px]"></div>

      <VoiceButton />
    </Page>
  );
};

export default CertEditPage;
